import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SEO from "../../components/SEO/SEO";

import CardConteudo from "../../components/CardConteudo/CardConteudo";

import imgAmbientesMulticulturais from "../../assets/images/a-escola/internacionalizacao/AmbientesMulticulturais.png";
import imgBuddy from "../../assets/images/a-escola/internacionalizacao/Buddy.png";
import imgIntercâmbiosEmissivos from "../../assets/images/a-escola/internacionalizacao/IntercâmbiosEmissivos.png";
import imgIntercâmbiosEParcerias from "../../assets/images/a-escola/internacionalizacao/IntercâmbiosEParcerias.png";
import imgIntercâmbiosReceptivos from "../../assets/images/a-escola/internacionalizacao/IntercâmbiosReceptivos.png";
import imgProgramaHostFamily from "../../assets/images/a-escola/internacionalizacao/ProgramaHostFamily.png";
import imgInternacionalizacao from "../../assets/images/a-escola/internacionalizacao/internacionalizacao.png";

export default function Internacionalizacao() {
	let oportunidades = [
		{
			title: "AMBIENTES MULTICULTURAIS DE APRENDIZAGEM",
			description:
				"na Escola Feevale, os estudantes aprendem desde cedo que a multiculturalidade é algo integrador, que enriquece a experiência de cada um e amplia o olhar para o mundo.</br>Os ambientes de aprendizagem são multiculturais, isso tudo para desenvolver habilidades linguísticas, além de promover o respeito às diversidades, visando desenvolvimento das habilidades e competências da cidadania global.",
			image: imgAmbientesMulticulturais,
			borderColor: "ensino",
			imgLeft: true,
		},
		{
			title: "INTERCÂMBIOS E PARCERIAS INTERNACIONAIS",
			description:
				"a Escola Feevale organiza intercâmbios para ampliar experiências e promover imersões internacionais.",
			image: imgIntercâmbiosEParcerias,
			borderColor: "ensino",
		},
		{
			title: "INTERCÂMBIOS EMISSIVOS",
			description:
				"têm como objetivo o compartilhamento de experiências entre estudantes da Escola Feevale e de escolas parceiras no exterior, proporcionando trocas culturais, práticas idiomáticas (geralmente em inglês ou espanhol), participação em atividades escolares, culturais e esportivas, além da acomodação em casa de família com os benefícios que essa vivência pode oferecer.",
			image: imgIntercâmbiosEmissivos,
			borderColor: "ensino",
			imgLeft: true,
		},
		{
			title: "INTERCÂMBIOS RECEPTIVOS",
			description:
				"a internacionalização em casa. A presença de professores visitantes e estudantes de outros países promovem troca de conhecimentos, integrando culturas e ampliando perspectivas.",
			image: imgIntercâmbiosReceptivos,
			borderColor: "ensino",
		},
		{
			title: "PROGRAMA HOST FAMILY",
			description:
				"possibilita aos estudantes hospedarem, em suas casas, os visitantes internacionais, integrando-os nas rotinas e atividades cotidianas, oferecendo infraestrutura e apoio necessário durante a estadia.",
			image: imgProgramaHostFamily,
			borderColor: "ensino",
			imgLeft: true,
		},
		{
			title: "BUDDY",
			description:
				"é a oportunidade de viajar o mundo sem sair do país, exercendo na própria Escola a função de facilitador local do estudante internacional, interagindo multiculturalmente e estabelecendo vínculos que podem permanecer após o término do programa.",
			image: imgBuddy,
			borderColor: "ensino",
		},
	];

	return (
		<Layout>
			<SEO title="Internacionalização" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol
						col={12}
						md={6}
						className="pt-0 pt-md-5 order-1 order-sm-0 my-auto"
					>
						<h1 className="pageTitle fgterciary-gradient d-none d-md-block" style={{ fontSize: "3.7em" }}>
							internacionalização
						</h1>
						<h1 className="pageTitle fgterciary-gradient d-block d-md-none" style={{ fontSize: "2.3em" }}>
							internacionalização
						</h1>
						<h3 className="fgterciary-gradient">sobre as oportunidades</h3>
						<p className="text-justify">
							A Escola Feevale promove programas internacionais, de maneira
							presencial e virtual, com a rede de parceiros no exterior,
							conectando os estudantes, desde a Educação Infantil até o Ensino
							Médio, com instituições de vários continentes. Essas conexões
							oportunizam benefícios culturais, idiomáticos e desenvolvimento de
							habilidades.
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="pt-5 text-justify order-0 order-sm-1"
					>
						<img src={imgInternacionalizacao} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				{oportunidades ? (
					<>
						{oportunidades.map((oportunidade, index) => (
							<CardConteudo
								key={index}
								title={oportunidade.title}
								description={oportunidade.description}
								borderColor={oportunidade.borderColor}
								img={oportunidade.image}
								imgLeft={oportunidade.imgLeft}
							/>
						))}
					</>
				) : (
					<p>Em breve novos programas internacionais.</p>
				)}
			</MDBContainer>
		</Layout>
	);
}
